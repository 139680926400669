<template>
    <div>
        <!-- Header - app-header.vue -->
        <!-- <Head>
            <Title> Revagency | {{ pageMeta.title }} </Title>
            <Meta name="description"> {{ pageMeta.description }} </Meta>
        </Head> -->
        <!-- Set meta manually until we start using $fetch -->
        <Head>
            <Title> Revagency | Performance Based Lead Distribution </Title>
            <Meta name="description">
                We have answers to questions you haven't even thought of.
            </Meta>
        </Head>

        <!-- Section 1 - Hero -->
        <section
            class="container mx-auto px-4 md:px-6 lg:px-8 pt-16 lg:pt-24 pb-24 text-center"
        >
            <h1
                class="mx-auto max-w-2xl text-5xl font-semibold text-slate-900 sm:text-7xl"
            >
                <span class="highlight z-0"><span>Performance</span></span>
                <span class="relative z-10"> Based Lead Distribution</span>
                <!-- <span class="relative whitespace-nowrap text-blue-600">
                    <svg
                        aria-hidden="true"
                        viewBox="0 0 418 42"
                        class="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"
                        />
                    </svg>
                    <span class="relative">Performance</span>
                </span>
                Based Lead Distribution -->
            </h1>
            <p
                class="mx-auto mt-6 max-w-2xl text-2xl font-light text-slate-700"
            >
                We have answers to questions you haven't even thought of.
            </p>
            <!-- <div class="mt-36 lg:mt-40">
                <p class="font-display text-slate-900">
                    Trusted by these companies
                </p>
                <img
                    class="mt-6 mx-auto opacity-80"
                    width="160"
                    height="54"
                    src="https://res.cloudinary.com/avoya-travel/image/upload/f_auto,q_auto/v1661799955/layout/logo-avoya-color.svg"
                    alt="Avoya Travel Logo"
                />
            </div> -->
        </section>

        <!-- Section 2 - BG image / 3 Icon Feature -->
        <section
            class="pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32 text-white bg-cover bg-center"
            style="
                background-image: url('https://res.cloudinary.com/avoya-travel/image/upload/f_auto,q_auto/v1673657503/revagency-com/northernlights.jpg');
            "
        >
            <div class="container mx-auto px-4 md:px-6 lg:px-8">
                <div class="mx-auto max-w-2xl md:text-center">
                    <h2 class="tracking-tight text-3xl md:text-5xl">
                        Simplify your optimization needs.
                    </h2>
                    <p class="mt-4 text-xl tracking-tight">
                        Because you'd probably be a little confused if we
                        suggested you complicate your everyday business tasks
                        instead.
                    </p>
                </div>

                <!-- Mobile Icons Feature
                <div
                    class="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden"
                >
                    {features.map((feature) => (
                    <div key="{feature.name}">
                        <Feature
                            feature="{feature}"
                            class="mx-auto max-w-2xl"
                            is-active
                        />
                        <div class="relative mt-10 pb-10">
                            <div
                                class="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6"
                            />
                            <div
                                class="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10"
                            >
                                <Image
                                    class="w-full"
                                    src="{feature.image}"
                                    alt=""
                                    sizes="52.75rem"
                                />
                            </div>
                        </div>
                    </div>
                    ))}
                </div>-->

                <!-- Desktop -->
                <div class="mt-24 flex flex-col lg:flex-row">
                    <div
                        class="w-full lg:w-1/3 px-10 mb-20 lg:mb-0 text-center"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        <div class="mb-6">
                            <FontAwesomeIcon
                                :icon="['fal', 'chart-mixed']"
                                class="mx-auto"
                                size="5x"
                            />
                        </div>
                        <h3 class="mt-2 text-3xl font-semibold">Solutions</h3>
                        <h3 class="mt-4 text-lg">
                            An array of solutions sets RevAgency and its client
                            distinctly apart from their competitors.
                        </h3>
                    </div>
                    <div
                        class="w-full lg:w-1/3 px-10 mb-20 lg:mb-0 text-center"
                        data-aos="fade-up"
                        data-aos-delay="150"
                        data-aos-once="true"
                    >
                        <div class="mb-6">
                            <FontAwesomeIcon
                                :icon="['fal', 'laptop-code']"
                                class="mx-auto"
                                size="5x"
                            />
                        </div>
                        <h3 class="mt-2 text-3xl font-semibold">Technology</h3>
                        <h3 class="mt-4 text-lg">
                            RevAgency's enterprise-class technology powers
                            companies around the globe.
                        </h3>
                    </div>
                    <div
                        class="w-full lg:w-1/3 px-10 text-center"
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-once="true"
                    >
                        <div class="mb-6">
                            <FontAwesomeIcon
                                :icon="['fal', 'clipboard-list']"
                                class="mx-auto"
                                size="5x"
                            />
                        </div>
                        <h3 class="mt-2 text-3xl font-semibold">Services</h3>
                        <h3 class="mt-4 text-lg">
                            Travel Agency Sales and Marketing Technology
                            Services.
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <!-- Footer - app-footer.vue -->
    </div>
</template>

<script type="ts">
//IMPORTS
import revBrain from "rev-brain";

export default defineComponent({
    async setup() {
        //CONFIG
        const config = useRuntimeConfig()

        definePageMeta({
            pageTransition: true, //Disable these if you don't want a transition on page or layout change
            layoutTransition: true,
            //  onBeforeEnter: (el) => {
            //      console.log('Before enter...')
            //     debugger;
            //     },
            // onEnter: (el, done) => {
            //     debugger;
            // },
            // onAfterEnter: (el) => { debugger }
        })

        //STORES

        //DATA
        if (process.server) {
            const cmsPageData = await revBrain.apiCMS.getPage(config, "revagency-com-content", "home-page");

            const pageContent = {
                header: cmsPageData.data[0].pagecontent.elementsMap[
                    "tailwind-hero-simple-centered-a81c"
                ].elementsMap.header,
                subHeader:
                    cmsPageData.data[0].pagecontent.elementsMap[
                        "tailwind-hero-simple-centered-a81c"
                    ].elementsMap["sub-header"],
            };
            const pageMeta = {
                title: cmsPageData.data[0].meta.elementsMap["MetaData-f946"]
                    .elementsMap["page-title"],
                description:
                    cmsPageData.data[0].meta.elementsMap["MetaData-f946"]
                        .elementsMap.description,
            };

            //COMPUTED

            //METHODS
            //Lifecyle Methods

            //Exported Methods

            return { pageContent, pageMeta };
        } else {
            const pageMeta = { title: "", description: ""}
            return {pageMeta}
        }
    },
});
</script>
